import React from "react";
import { Typography, Link } from "@mui/material";
import { AppBarButton } from "./AppBarButton";


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = (props) => {
    //props.login(props.LoginResources)
    return (
        <AppBarButton data-qa-element="login" component={Link}
            onClick={() => props.login(props.LoginResources)}>
            <Typography variant="navlink">Login</Typography>
        </AppBarButton>
    );
}