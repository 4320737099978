import React, { useState, useMemo, memo, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
//import { RouteConfig } from "../../Configs/RouteConfig"

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Icon from "@mui/material/Icon";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { AppBarButton } from "./AppBarButton"
//import { getProfilePhotoURL, getUserProfile } from "../../graph";
//import { useLocation } from "react-router-dom";
import MsalHelper from "../../MsalHelper";
import { getUserPermissionSession } from "../../fetch";
import StateStore from "../../StateStore";

export const AppNavBar = memo((props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [subMenu, setSubMenu] = React.useState({});
    const menuSelection = useRef({ selected: { 0: "Home" }, progress: {} });
    const isAuthenticated = useIsAuthenticated();
    //const instance = MsalHelper.getAppInstance();
    const name = MsalHelper.getName();
    const username = MsalHelper.getEmail();
    const [anchorElNav, setAnchorElNav] = useState(null);
    //const [imageUrl, setImageUrl] = useState(null)
    //const [userData, setUserData] = useState(null)
    const navigate = useNavigate();
    //const location = useLocation();

    // const active = location.state?.url || "/" ;
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const permission = getUserPermissionSession();

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const IsSelected = (pageCaption, level) => {
        return menuSelection.current.selected[level] === pageCaption
    }


    //#region ***** State Managment across components
    const stateStore = new StateStore("test");

    const [, setRefresh] = useState();

    const UpdateComponent = (value) => {
        menuSelection.current = {
            selected: { ...value }, progress: {}
        }
        setTimeout(() => { setRefresh(value); }, 0)
    };

    useEffect(() => {
        stateStore.Subscribe("AppNavBar", "AppNavBar", UpdateComponent);
        return () => {
            stateStore.UnSubscribe("AppNavBar", "AppNavBar");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************************************************
    //#endregion

    const GetMenu = (menuItems, anchor) => {

        return (<Menu open={true}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={(e) => handleClose(e)}
        >
            <>
                {GetMenuItems(menuItems)}
            </>
        </Menu>)

    }

    const GetMenuItems = (menuItems, level = 1) => {
        return (
            menuItems && menuItems.map(page =>
                <>

                    <MenuItem onClick={(e) => {
                        menuSelection.current.progress[level] = page.caption;
                        if (page.routes?.length > 0) {
                            if (subMenu[level] === page.caption) {
                                let sMenu = { ...subMenu, [level]: undefined };
                                setSubMenu(sMenu)
                            }
                            else {
                                let sMenu = { ...subMenu, [level]: page.caption };
                                setSubMenu(sMenu)
                            }
                        }
                        else {
                            menuSelection.current = {
                                selected: { ...menuSelection.current.progress }, progress: {}
                            }
                            navigate(page.url, { state: { title: page.caption, url: page.url } })
                            setTimeout(() => {
                                setSubMenu({});
                                setAnchorEl(null);
                            }, 1000)
                        }
                    }}
                        sx={{ textIndent: level + 'em' }}
                    >
                        <Typography variant="body1" style={{ display: 'flex', fontWeight: IsSelected(page.caption, level) ? 650 : 0 }}> {page.caption}
                            {page.routes && <Icon sx={{
                                fontSize: '1.2rem',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}>{subMenu && subMenu[level] === page.caption ? 'expand_more' : 'chevron_right'}</Icon>}
                        </Typography>

                    </MenuItem>
                    {page.routes && subMenu && subMenu[level] === page.caption && GetMenuItems(page.routes.filter(item => permission?.some(p => p.toLowerCase() === item.caption.toLowerCase() || p.toLowerCase() === item.keyname?.toLowerCase())), level + 1)}

                </>
            )
        )

    }


    const handleClose = (e,) => {
        setSubMenu({})
        setAnchorEl(null);
    };


    return (
        <>
            <AppBar data-qa-container="appBar" position="fixed" color="submenu" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>

                    <Box sx={{ flexGrow: 1, display: { xs: 'contents', md: 'none', } }}>

                        <Icon size="large" edge="start"
                            aria-label="menu"
                            onClick={handleOpenNavMenu}
                            data-qa-element="navMenuButton"
                            sx={{ mr: 1 }}>menu</Icon>
                        <Menu data-qa-container="navmenu"
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                my: 6,
                                mx: -3,

                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {GetMenuItems(props.RouteConfig?.routes?.filter(item => item.type === "topmenu" && (permission?.some(p => p.toLowerCase() === item.caption.toLowerCase() || p.toLowerCase() === item.keyname?.toLowerCase()) || item.caption === "Home")), 0)}
                        </Menu>
                    </Box>

                    {useMemo(() => <Box
                        variant="h5"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex' },
                            flexGrow: 1,
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Box sx={{
                            mr: 2,
                            display: { xs: 'flex' },
                            flexGrow: 1

                        }} class="sonocoLogo"></Box>





                        <Box sx={{
                            justifyCotent: 'center',
                            //display: { xs: 'none', md: 'none', lg: 'flex' },
                            flexGrow: 1,
                        }} class="headerTitle">
                            <Typography variant='h5' noWrap
                                sx={{

                                    display: 'flex',
                                    flexGrow: 1,
                                    color: '#fff',
                                    textAlign: "center",
                                    height: '100%',
                                    fontFamily: "proxima-nova,Arial,Helvetica,sans-serif"

                                }}>
                                {props.appName} {process.env.REACT_APP_ENV === "PROD" ? "" : "(" + process.env.REACT_APP_ENV + ")"}
                            </Typography>
                        </Box>
                    </Box>, [props.appName])}
                    <Stack direction="row" spacing={3} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', 'justifyContent': 'flex-end', alignItems: 'center' } }} >
                        {isAuthenticated ? <>
                            {props.RouteConfig?.routes?.filter(item => item.type === "topmenu" && (permission?.some(p => p.toLowerCase() === item.caption.toLowerCase() || p.toLowerCase() === item.keyname?.toLowerCase()) || item.caption === "Home")).map((page) => (
                                <>
                                    <AppBarButton
                                        id={"test"}
                                        data-qa-element={page.caption}
                                        component={Link}
                                        key={page.id}
                                        onClick={(e) => {
                                            menuSelection.current.progress[0] = page.caption;
                                            if (page.routes?.length > 0) {
                                                setAnchorEl(e.currentTarget)
                                            }
                                            else {
                                                menuSelection.current = {
                                                    selected: { ...menuSelection.current.progress }, progress: {}
                                                }
                                                navigate(page.url, { state: { title: page.caption, url: page.url } });
                                            }

                                        }}
                                    >
                                        <Typography style={{ cursor: 'pointer', textDecoration: IsSelected(page.caption, 0) ? "underline" : "none", display: 'flex' }} variant="navlink">{page.caption}{page.routes && <Icon sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end'
                                        }}>expand_more</Icon>}</Typography>

                                    </AppBarButton>
                                    {anchorEl && page.routes && menuSelection.current.progress[0] === page.caption && GetMenu(page.routes, anchorEl)}
                                </>
                            ))}
                            <SignOutButton name={name} username={username} logout={MsalHelper.logOut} navigate={navigate} ProfileMenuConfig={props.ProfileMenuConfig} /> </>
                            :
                            <SignInButton login={MsalHelper.login} LoginResources={props.LoginResources} />
                        }

                    </Stack>


                </Toolbar>
            </AppBar>
        </>
    );
});