import { PublicClientApplication, InteractionRequiredAuthError, LogLevel } from "@azure/msal-browser";

var msalInstance = {}

const msalConfigPart = {
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                };
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

//const loginRequest = {
//   // scopes: ["User.ReadBasic.All"]
//};

const MsalHelper = {
    login(loginRequest) {
        const urlParams = new URLSearchParams(window.location.search);
        const uid = urlParams.get("uid");
        let loginType = "redirect";
        if (uid) {
            loginRequest.loginHint = uid;
            loginType = "popup";
        }

        msalInstance.ssoSilent({
            loginRequest
        }).then(response => {
            console.log("authenticated")
        }).catch(error => {
            // handle errors
            if (error.errorCode === "interaction_required" || error.errorCode === "login_required") {
                if (loginType === "redirect") {
                    msalInstance.loginRedirect(loginRequest).catch(e => {
                        console.error(e);
                    });
                }
                else {
                    msalInstance.loginPopup(loginRequest).catch(e => {
                        console.error(e);
                    });
                }

            }
            else {
                console.error(error);
                // window.location.url = "http://localhost/error";
            }
        });

    },
    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts.length > 0;
    },
    logOut() {
        msalInstance.logoutRedirect().catch(e => {
            console.error(e);
        });
    },
    getAppInstance(authCfg) {
        if (!authCfg) {
            return null;
        }
        let msalConfig = { ...msalConfigPart,auth: { ...authCfg } };
        msalInstance = new PublicClientApplication({ ...msalConfig });
        return msalInstance;
    },
    getAccount(){
        return msalInstance?.getAllAccounts()[0];
    },
    getName() {
        return msalInstance?.getAllAccounts()[0]?.name
    },
    getEmail() {
        return msalInstance?.getAllAccounts()[0]?.username
    },
    getUserID() {
        return msalInstance?.getAllAccounts()[0]?.localAccountId
    },
    getAccessToken(scopes)
    {
        const apiRequest = {
            scopes: scopes,
            account: this.getAccount()
        };
        return msalInstance.acquireTokenSilent(apiRequest).then((response) => response.accessToken).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(apiRequest).then((response) => response.accessToken);
            }
        });
    },
    //getGraphAPIAccessToken1() {
    //    const apiRequest = {
    //        scopes: loginRequest.scopes,
    //        account: this.getAccount()
    //    };
    //    return msalInstance.acquireTokenSilent(apiRequest).then((response) => response.accessToken).catch((error) => {
    //        if (error instanceof InteractionRequiredAuthError) {
    //            msalInstance.acquireTokenRedirect(apiRequest).then((response) => response.accessToken);
    //        }
    //    });
    //}
}

export default MsalHelper;