import { Store } from "./Store";
import StateStore from "./StateStore";


export const MessageHandler = (errorType, redirect, msg) => {

    const stateStore = new StateStore("Error");
    const appStore = new Store("app");

    let error = { severity: "error", message: "Error occurred", type: errorType }
    switch (errorType) {
        case "noaccess":
            error = { ...error, severity: "error", message: "You are not authorized to perform this operation." }
            break;
        case "notfound":
            error = { ...error, severity: "error", message: "Requested resource is not found." }
            break;
        case "error":
            error = { ...error, severity: "error", message: "Something went wrong!!! Try again, if error continues Please contact application support." }
            break;
        case "success":
            if (msg) {
                error = { ...error, severity: "success", message: msg }
            }
            else {
                error = { ...error, severity: "success", message: "Successfully completed." }
            }
            
            break;
        default:
    }
    appStore.Set("Error", error);
    if (redirect === true && errorType !=="success") {
        stateStore.Set("alert", errorType);
    }
    else {
        stateStore.Set("alert", Math.random());
    }
}
