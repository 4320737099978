const timeZoneOffsetMap = {
    'ACDT': '+10:30',
    'ACST': '+09:30',
    'ACT': '+09:30',
    'ADT': '-03:00',
    'AEDT': '+11:00',
    'AEST': '+10:00',
    'AFT': '+04:30',
    'AKDT': '-08:00',
    'AKST': '-09:00',
    'ALMT': '+06:00',
    'AMST': '-03:00',
    'AMT': '-04:00',
    'ANAT': '+12:00',
    'AQTT': '+05:00',
    'ART': '-03:00',
    'AST': '-04:00',
    'AWST': '+08:00',
    'AZOST': '-01:00',
    'AZOT': '-01:00',
    'AZT': '+04:00',
    'BDT': '+08:00',
    'BIOT': '+06:00',
    'BIT': '-12:00',
    'BOT': '-04:00',
    'BRST': '-02:00',
    'BRT': '-03:00',
    'BST': '+01:00',
    'BTT': '+06:00',
    'CAT': '+02:00',
    'CCT': '+06:30',
    'CDT': '-05:00',
    'CEST': '+02:00',
    'CET': '+01:00',
    'CHADT': '+13:45',
    'CHAST': '+12:45',
    'CHOT': '+08:00',
    'CHUT': '+10:00',
    'CIST': '-08:00',
    'CKT': '-10:00',
    'CLST': '-03:00',
    'CLT': '-04:00',
    'COST': '-05:00',
    'COT': '-05:00',
    'CST': '-06:00',
    'CT': '+08:00',
    'CVT': '-01:00',
    'CWST': '+08:45',
    'CXT': '+07:00',
    'DAVT': '+07:00',
    'DDUT': '+10:00',
    'DFT': '+01:00',
    'EASST': '-05:00',
    'EAST': '-06:00',
    'EAT': '+03:00',
    'ECT': '-05:00',
    'EDT': '-04:00',
    'EEST': '+03:00',
    'EET': '+02:00',
    'EGST': '+00:00',
    'EGT': '-01:00',
    'EST': '-05:00',
    'FET': '+03:00',
    'FJT': '+12:00',
    'FKST': '-03:00',
    'FKT': '-04:00',
    'FNT': '-02:00',
    'GALT': '-06:00',
    'GAMT': '-09:00',
    'GET': '+04:00',
    'GFT': '-03:00',
    'GILT': '+12:00',
    'GIT': '-09:00',
    'GMT': '+00:00',
    'GST': '+04:00',
    'GYT': '-04:00',
    'HDT': '-09:00',
    'HAEC': '+02:00',
    'HST': '-10:00',
    'HKT': '+08:00',
    'HMT': '+05:30',
    'HOVT': '+07:00',
    'ICT': '+07:00',
    'IDLW': '-12:00',
    'IDT': '+03:00',
    'IOT': '+06:00',
    'IRDT': '+04:30',
    'IRKT': '+08:00',
    'IRST': '+03:30',
    'IST': '+05:30',
    'JST': '+09:00',
    'KGT': '+06:00',
    'KOST': '+11:00',
    'KRAT': '+07:00',
    'KST': '+09:00',
    'LHST': '+10:30',
    'LINT': '+14:00',
    'MAGT': '+11:00',
    'MART': '-09:30',
    'MAWT': '+05:00',
    'MDT': '-06:00',
    'MET': '+01:00',
    'MEST': '+02:00',
    'MHT': '+12:00',
    'MIST': '+11:00',
    'MIT': '-09:30',
    'MMT': '+06:30',
    'MSK': '+03:00',
    'MST': '-07:00',
    'MUT': '+04:00',
    'MVT': '+05:00',
    'MYT': '+08:00',
    'NCT': '+11:00',
    'NDT': '-02:30',
    'NFT': '+11:00',
    'NOVT': '+07:00',
    'NPT': '+05:45',
    'NST': '-03:30',
    'NT': '-03:30',
    'NUT': '-11:00',
    'NZDT': '+13:00',
    'NZST': '+12:00',
    'OMST': '+06:00',
    'ORAT': '+05:00',
    'PDT': '-07:00',
    'PET': '-05:00',
    'PETT': '+12:00',
    'PGT': '+10:00',
    'PHOT': '+13:00',
    'PHT': '+08:00',
    'PKT': '+05:00',
    'PMDT': '-02:00',
    'PMST': '-03:00',
    'PONT': '+11:00',
    'PST': '-08:00',
    'PWT': '+09:00',
    'PYST': '-03:00',
    'PYT': '-04:00',
    'RET': '+04:00',
    'ROTT': '-03:00',
    'SAKT': '+11:00',
    'SAMT': '+04:00',
    'SAST': '+02:00',
    'SBT': '+11:00',
    'SCT': '+04:00',
    'SGT': '+08:00',
    'SLST': '+05:30',
    'SRET': '+11:00',
    'SRT': '-03:00',
    'SST': '-11:00',
    'SYOT': '+03:00',
    'TAHT': '-10:00',
    'THA': '+07:00',
    'TFT': '+05:00',
    'TJT': '+05:00',
    'TKT': '+13:00',
    'TLT': '+09:00',
    'TMT': '+05:00',
    'TRT': '+03:00',
    'TOT': '+13:00',
    'TVT': '+12:00',
    'ULAT': '+08:00',
    'UTC': '+00:00',
    'UYST': '-02:00',
    'UYT': '-03:00',
    'UZT': '+05:00',
    'VET': '-04:00',
    'VLAT': '+10:00',
    'VOLT': '+04:00',
    'VOST': '+06:00',
    'VUT': '+11:00',
    'WAKT': '+12:00',
    'WAST': '+02:00',
    'WAT': '+01:00',
    'WEST': '+01:00',
    'WET': '+00:00',
    'WIT': '+09:00',
    'WST': '+08:00',
    'YAKT': '+09:00',
    'YEKT': '+05:00'
};
const timeZoneMap = {
    'ACDT': 'Australia/Adelaide',
    'ACST': 'Australia/Darwin',
    'ACT': 'Australia/Darwin',
    'ADT': 'America/Halifax',
    'AEDT': 'Australia/Sydney',
    'AEST': 'Australia/Brisbane',
    'AFT': 'Asia/Kabul',
    'AKDT': 'America/Anchorage',
    'AKST': 'America/Anchorage',
    'ALMT': 'Asia/Almaty',
    'AMST': 'America/Campo_Grande',
    'AMT': 'America/Campo_Grande',
    'ANAT': 'Asia/Anadyr',
    'AQTT': 'Asia/Aqtobe',
    'ART': 'America/Argentina/Buenos_Aires',
    'AST': 'America/Halifax',
    'AWST': 'Australia/Perth',
    'AZOST': 'Atlantic/Azores',
    'AZOT': 'Atlantic/Azores',
    'AZT': 'Asia/Baku',
    'BDT': 'Asia/Dhaka',
    'BIOT': 'Indian/Chagos',
    'BIT': 'Pacific/Pago_Pago',
    'BOT': 'America/La_Paz',
    'BRST': 'America/Sao_Paulo',
    'BRT': 'America/Sao_Paulo',
    'BST': 'Europe/London',
    'BTT': 'Asia/Thimphu',
    'CAT': 'Africa/Harare',
    'CCT': 'Indian/Cocos',
    'CDT': 'America/Chicago',
    'CEST': 'Europe/Paris',
    'CET': 'Europe/Paris',
    'CHADT': 'Pacific/Chatham',
    'CHAST': 'Pacific/Chatham',
    'CHOT': 'Asia/Choibalsan',
    'CHUT': 'Pacific/Chuuk',
    'CIST': 'Pacific/Rarotonga',
    'CKT': 'Pacific/Rarotonga',
    'CLST': 'America/Santiago',
    'CLT': 'America/Santiago',
    'COST': 'America/Bogota',
    'COT': 'America/Bogota',
    'CST': 'America/Chicago',
    'CT': 'Asia/Shanghai',
    'CVT': 'Atlantic/Cape_Verde',
    'CWST': 'Australia/Eucla',
    'CXT': 'Indian/Christmas',
    'DAVT': 'Antarctica/Davis',
    'DDUT': 'Antarctica/DumontDUrville',
    'DFT': 'Europe/Paris',
    'EASST': 'Pacific/Easter',
    'EAST': 'Pacific/Easter',
    'EAT': 'Africa/Nairobi',
    'ECT': 'America/Guayaquil',
    'EDT': 'America/New_York',
    'EEST': 'Europe/Athens',
    'EET': 'Europe/Athens',
    'EGST': 'America/Scoresbysund',
    'EGT': 'America/Scoresbysund',
    'EST': 'America/New_York',
    'FET': 'Europe/Kaliningrad',
    'FJT': 'Pacific/Fiji',
    'FKST': 'Atlantic/Stanley',
    'FKT': 'Atlantic/Stanley',
    'FNT': 'America/Noronha',
    'GALT': 'Pacific/Galapagos',
    'GAMT': 'Pacific/Gambier',
    'GET': 'Asia/Tbilisi',
    'GFT': 'America/Cayenne',
    'GILT': 'Pacific/Tarawa',
    'GIT': 'Pacific/Gambier',
    'GMT': 'Europe/London',
    'GST': 'Asia/Dubai',
    'GYT': 'America/Guyana',
    'HDT': 'Pacific/Honolulu',
    'HAEC': 'Europe/Paris',
    'HST': 'Pacific/Honolulu',
    'HKT': 'Asia/Hong_Kong',
    'HMT': 'Asia/Kolkata',
    'HOVT': 'Asia/Hovd',
    'ICT': 'Asia/Bangkok',
    'IDLW': 'Pacific/Apia',
    'IDT': 'Asia/Jerusalem',
    'IOT': 'Indian/Chagos',
    'IRDT': 'Asia/Tehran',
    'IRKT': 'Asia/Irkutsk',
    'IRST': 'Asia/Tehran',
    'IST': 'Asia/Kolkata',
    'JST': 'Asia/Tokyo',
    'KGT': 'Asia/Bishkek',
    'KOST': 'Pacific/Kosrae',
    'KRAT': 'Asia/Krasnoyarsk',
    'KST': 'Asia/Seoul',
    'LHST': 'Australia/Lord_Howe',
    'LINT': 'Pacific/Kiritimati',
    'MAGT': 'Asia/Magadan',
    'MART': 'Pacific/Marquesas',
    'MAWT': 'Antarctica/Mawson',
    'MDT': 'America/Denver',
    'MET': 'Europe/Paris',
    'MEST': 'Europe/Paris',
    'MHT': 'Pacific/Kwajalein',
    'MIST': 'Antarctica/Macquarie',
    'MIT': 'Pacific/Apia',
    'MMT': 'Asia/Yangon',
    'MSK': 'Europe/Moscow',
    'MST': 'America/Denver',
    'MUT': 'Indian/Mauritius',
    'MVT': 'Indian/Maldives',
    'MYT': 'Asia/Kuala_Lumpur',
    'NCT': 'Pacific/Noumea',
    'NDT': 'America/St_Johns',
    'NFT': 'Pacific/Norfolk',
    'NOVT': 'Asia/Novosibirsk',
    'NPT': 'Asia/Kathmandu',
    'NST': 'America/St_Johns',
    'NT': 'America/St_Johns',
    'NUT': 'Pacific/Niue',
    'NZDT': 'Pacific/Auckland',
    'NZST': 'Pacific/Auckland',
    'OMST': 'Asia/Omsk',
    'ORAT': 'Asia/Oral',
    'PDT': 'America/Los_Angeles',
    'PET': 'America/Lima',
    'PETT': 'Asia/Kamchatka',
    'PGT': 'Pacific/Port_Moresby',
    'PHOT': 'Pacific/Enderbury',
    'PHT': 'Asia/Manila',
    'PKT': 'Asia/Karachi',
    'PMDT': 'America/Miquelon',
    'PMST': 'America/Miquelon',
    'PONT': 'Pacific/Pohnpei',
    'PST': 'America/Los_Angeles',
    'PWT': 'Pacific/Palau',
    'PYST': 'America/Asuncion',
    'PYT': 'America/Asuncion',
    'RET': 'Indian/Reunion',
    'ROTT': 'Antarctica/Rothera',
    'SAKT': 'Asia/Sakhalin',
    'SAMT': 'Europe/Samara',
    'SAST': 'Africa/Johannesburg',
    'SBT': 'Pacific/Guadalcanal',
    'SCT': 'Indian/Mahe',
    'SGT': 'Asia/Singapore',
    'SLST': 'Asia/Colombo',
    'SRET': 'Asia/Srednekolymsk',
    'SRT': 'America/Paramaribo',
    'SST': 'Pacific/Pago_Pago',
    'SYOT': 'Antarctica/Syowa',
    'TAHT': 'Pacific/Tahiti',
    'THA': 'Asia/Bangkok',
    'TFT': 'Indian/Kerguelen',
    'TJT': 'Asia/Dushanbe',
    'TKT': 'Pacific/Fakaofo',
    'TLT': 'Asia/Dili',
    'TMT': 'Asia/Ashgabat',
    'TRT': 'Europe/Istanbul',
    'TOT': 'Pacific/Tongatapu',
    'TVT': 'Pacific/Funafuti',
    'ULAT': 'Asia/Ulaanbaatar',
    'UTC': 'Etc/UTC',
    'UYST': 'America/Montevideo',
    'UYT': 'America/Montevideo',
    'UZT': 'Asia/Tashkent',
    'VET': 'America/Caracas',
    'VLAT': 'Asia/Vladivostok',
    'VOLT': 'Europe/Volgograd',
    'VOST': 'Antarctica/Vostok',
    'VUT': 'Pacific/Efate',
    'WAKT': 'Pacific/Wake',
    'WAST': 'Africa/Windhoek',
    'WAT': 'Africa/Lagos',
    'WEST': 'Europe/Lisbon',
    'WET': 'Europe/Lisbon',
    'WIT': 'Asia/Jayapura',
    'WST': 'Australia/Perth',
    'YAKT': 'Asia/Yakutsk',
    'YEKT': 'Asia/Yekaterinburg'
};

const DateTimeFormat = {

    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
};

const DateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
};

const locale = (navigator && navigator.language) || "en";

export function ConvertDate(dateString, inputTimeZone, outpuTimeZone, format, includeTime) {



    // Create a new Date object from the input date string
    if (inputTimeZone) {
        let inputOffset = timeZoneOffsetMap[inputTimeZone];
        dateString = dateString + inputOffset;
    }
    let date = new Date(dateString);

    // Convert the date to the input timezone

    if (outpuTimeZone) {
        let outputOffset = timeZoneMap[outpuTimeZone];
        let outputDate = new Date(date).toLocaleString(locale, { timeZone: outputOffset });
        date = new Date(outputDate);
    }


    if (format) {
        console.log('here')
        return FormatDate(date, format);
    }
    else {
        console.log('there')
        //send browser format
        if (includeTime)
            return new Intl.DateTimeFormat(locale, DateTimeFormat).format(date);
        else {
            return new Intl.DateTimeFormat(locale, DateFormat).format(date);
        }
    }
}

//function getOffsetInMilliseconds(offset) {
//    const [hours, minutes] = offset.split(':').map(Number);
//    return (hours * 60 + minutes) * 60 * 1000;
//}


function FormatDate(date, format) {
    const map = {
        'yyyy': date.getFullYear(),
        'MM': ('0' + (date.getMonth() + 1)).slice(-2),
        'dd': ('0' + date.getDate()).slice(-2),
        'hh': ('0' + date.getHours()).slice(-2),
        'HH': ('0' + date.getHours() % 12).slice(-2),
        'mm': ('0' + date.getMinutes()).slice(-2),
        'ss': ('0' + date.getSeconds()).slice(-2),
        'AM/PM': date.getHours() >= 12 ? 'PM' : 'AM'
    };

    return format.replace(/yyyy|MM|dd|hh|HH|mm|ss|AM\/PM/g, matched => map[matched]);
}

// Example usage
let datestring = '2024-08-16T09:30:41';
let shortInputTimeZone = 'UTC'; // Short input time zone format
let shortOutputTimeZone = 'EDT'; // Short output time zone format
let format = 'yyyy-MM-dd hh:mm:ss AM/PM';
console.log(ConvertDate(datestring, shortInputTimeZone, shortOutputTimeZone, format));